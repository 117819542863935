export default {
  // judul: {
  //     required: true,
  //     message: 'Judul tidak boleh kosong!',
  // },
  // target_name: {
  //     required: true,
  //     message: 'Nama Target tidak boleh kosong!',
  // },
  // deskripsi: {
  //     required: true,
  //     message: 'Deskripsi tidak boleh kosong!',
  // },
}
