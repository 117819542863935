const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Judul',
    dataIndex: 'section_name',
  },
  {
    title: 'Nama Program',
    dataIndex: 'program_name',
  },
  // {
  //   title: 'Id Toko',
  //   dataIndex: 'id_toko',
  // },
  {
    title: 'Nama Toko',
    dataIndex: 'vendor_name',
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
  },
  {
    title: 'Nama Target',
    dataIndex: 'target_name',
  },
  {
    title: 'Target Q1',
    dataIndex: 'target_q1',
  },
  {
    title: 'Target Q2',
    dataIndex: 'target_q2',
  },
  {
    title: 'Target Q3',
    dataIndex: 'target_q3',
  },
  {
    title: 'Target Q4',
    dataIndex: 'target_q4',
  },
  {
    title: 'Total Volume Pencapaian',
    slots: { customRender: 'volume' },
  },
  {
    title: 'Deskripsi',
    dataIndex: 'section_description',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
export default acolumns


