<template>
  <div>
    <md-table :columns="columns" :data-source="dataHistory" size="small">
      <template #no="{ index }">
        <span>
          {{ index + 1 }}
        </span>
      </template>
      <template #volume="{record}">
        <span v-if="record.id == formData.volume_id">
          <a-input
            type="number"
            style="width: 100px;"
            v-model:value="formData.volume"
            placeholder="Volume"
            @keypress="checkInput($event)"
            />
        </span>
        <span v-else>{{ record.volume }}</span>
      </template>
      <template #action="{record}">
        <span style="white-space:nowrap;">
          <a-tooltip title="Ubah">
            <a
              href="javascript: void(0);"
              class="btn btn-sm btn-light m-1"
              @click="edit(record)"
            >
              <small>
                <i class="fe fe-edit" />
              </small>
            </a>
          </a-tooltip>
        </span>
      </template>
    </md-table>
  </div>
</template>
<script>
import columnsHistory from './columns-history'

export default {
  props: {
    dataHistory: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: columnsHistory,
      formData: {
        volume_id: this.dataHistory.id,
        volume: this.dataHistory.volume,
      },
    }
  },
  mounted() {},

  methods: {
    edit(data) {
      this.formData.volume_id = data.id
      this.formData.volume = data.volume
      // this.dataHistory.isEdit = true
    },
    checkInput(e) {
      if (this.formData.volume.length >= 5) {
        e.preventDefault()
      }
      if (e.key == '-') {
        e.preventDefault()
      }
    },
  },
}
</script>
