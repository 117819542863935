<template>
  <div>
    <!-- <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    /> -->
    <div class="row">
      <div class="col-md-12">
        <filter-toko :mode="null" v-model:value="toko" class="mt-2 mb-2 mr-2" />
        <filter-regional
        class="mb-2 mt-2 mr-2"
          :mode="null"
          v-model:value="region"
          v-can:hide.distributor
        />
        <filter-brand
          :mode="null"
          class="mr-2 mt-2"
          v-model:value="brands"
          v-model:brands="brands"
        />
        <filter-program :mode="null" v-model:value="program" class="mt-2 mb-2 mr-2" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mb-2 mt-2 mr-2"
        />
        <a-button
          class="mb-2 mt-2 mr-2"
          type="primary"
          title="cari"
          @click="search"
          :loading="isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button title="Tambah Volume" type="primary" @click="openModalVolume()">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>

        <a-button
          class="ml-2"
          title="download excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="table-responsive text-nowrap mt-2">
      <md-table
        :columns="columns"
        :data-source="data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #volume="{text}">
          <div>
            {{ text.volume }}
          </div>
        </template>
        <template #action="{record}">
          <span style="white-space:nowrap;">
            <a-tooltip title="Tambah Volume">
              <a
                @click="volumeData(record)"
                href="javascript: void(0);"
                class="btn btn-sm btn-light m-1"
              >
                <small>
                  <i class="fa fa-plus text-primary" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip
              title="Lihat History Volume"
              v-if="record.volume_histories && record.volume_histories.length > 0"
            >
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light m-1"
                @click="lihatHistory(record.volume_histories)"
              >
                <small>
                  <i class="fe fe-eye" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Ubah Target">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light m-1"
                @click="editTarget(record)"
              >
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Hapus Target">
              <a
                @click="postDelete(record.target_id)"
                href="javascript: void(0);"
                class="btn btn-sm btn-light m-1"
              >
                <small>
                  <i class="fe fe-trash" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
  </div>
  <!-- <pre>{{ state }}</pre> -->
  <a-modal
    v-model:visible="modal3Visible"
    :title="formState.title_state"
    :destroy-on-close="true"
    width="1000px"
    :mask-closable="false"
    @ok="modal3Visible = false"
    v-if="modal3Visible"
  >
    <pre v-if="!true">{{ formState }}</pre>
    <template #footer>
      <a-button key="submitData" type="primary" @click="submitData()">Simpan</a-button>
    </template>
    <m-modal ref="modalRef" :data-target="formData"></m-modal>
  </a-modal>

  <!-- volume -->
  <a-modal
    v-model:visible="modal5Visible"
    :title="formState.title_state"
    :destroy-on-close="true"
    width="700px"
    :mask-closable="false"
    @ok="modal5Visible = false"
    v-if="modal5Visible"
  >
    <template #footer>
      <a-button @click="submitVolume()" type="primary" :hidden="formState.readOnly"
        >Simpan</a-button
      >
    </template>
    <volume-modal
      :edit-data-volume="editDataVolume"
      :data-volume="dataVolume"
      ref="modalRefVolume"
      :target-id="formData"
    ></volume-modal>
  </a-modal>

  <!-- history volume -->
  <a-modal
    v-model:visible="modal4Visible"
    :title="formState.title_state"
    :destroy-on-close="true"
    width="800px"
    :mask-closable="false"
    @ok="modal4Visible = false"
    v-if="modal4Visible"
  >
    <template #footer>
      <a-button @click="submitEditVolume()" type="primary" :hidden="!formState.isEdit">Simpan</a-button>
      <a-button @click="modal4Visible = false" type="secondary" :hidden="formState.readOnly">Tutup</a-button>
    </template>
    <history-volume-modal
      :data-history="formState"
      ref="modalRefHistoryVolume"
    ></history-volume-modal>
  </a-modal>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs, computed, h } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import apiClient from '@/services/axios'
import MModal from './modal'
// import RealisasiModal from './modal-realisasi.vue'
import VolumeModal from './modal-volume.vue'
import HistoryVolumeModal from './modal-history.vue'

import { Modal, message, Alert } from 'ant-design-vue'
import FilterRegional from '@/components/filter/FilterRegional'
// import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import FilterProgram from '@/components/filter/FilterProgram'
import FilterBrand from '@/components/filter/FilterBrand'
import qs from 'qs'
import moment from 'moment'
import { serialize } from 'object-to-formdata'
import useUserInfo from '@/composables/useUserInfo'
import acolumns from './columns'
import _ from 'lodash'

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
}
export default {
  name: 'MDataToko',
  components: {
    MModal,
    // RealisasiModal,
    VolumeModal,
    HistoryVolumeModal,
    FilterBrand,
    FilterRegional,
    // FilterArea,
    FilterToko,
    FilterProgram,
  },
  setup() {
    const route = useRoute()
    const modalRef = ref('')
    const modalRefVolume = ref('')
    const modalRefHistoryVolume = ref('')

    const modal1Visible = ref(false)
    const modal2Visible = ref(false)
    const modal3Visible = ref(false)
    const modal4Visible = ref(false)
    const modal5Visible = ref(false)
    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }

    const loading = ref(false)

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const region = ref([])
    const area = ref([])
    const brands = ref([])
    const program = ref([])
    const toko = ref([])
    const dataBrandList = ref([])
    // const modalRef = ref('')

    const dataRegional = ref([])
    const dataArea = ref([])
    const data = ref([
      {
        id_program: 1,
      },
    ])
    const q = ref('')
    const searchInput = ref(null)

    const isPosting = ref(false)
    const formState = ref({})
    const errorMessage = ref(null)
    const columns = ref([])
    columns.value = acolumns

    const startRow = ref(1)
    const handleTableChange = pag => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const params = ref({})
    const fetchData = () => {
      let brandName = dataBrandList.value.find(x => x.id === brands.value)

      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: region.value,
        // area: area.value,
        brand: brandName && brandName.name,
        program: program.value,
        q: q.value || toko.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/loyalty-target', {
          params: _params,
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = error.response.data.message.substring(0, 150)
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchDataBrand = q => {
      apiClient.get('/api/filter/brand?active=1', { params: q }).then(response => {
        dataBrandList.value = response.data
      })
    }
    const lihatHistory = data => {
      formState.value = data
      modal4Visible.value = true
      formState.value.title_state = 'Lihat History Volume'
      formState.value.isEdit = true

    }
    const realisasiData = id => {
      modal4Visible.value = true
      formState.value.title_state = 'Tambah Realisasi'
    }
    const postDelete = id => {
      Modal.confirm({
        title: 'Konfirmasi hapus pencapaian ',
        content: 'Apakah anda ingin hapus pencapaian loyalti ?',
        onOk() {
          apiClient
            .delete(`/api/loyalty-target/${id}`)
            .then(response => {
              const data = response.data
              formState.value = data
              fetchData()
              message.success('Berhasil dihapus')
            })
            .catch(e => message.error('Gagal menghapus!'))
        },
        onCancel() {},
      })
    }
    const search = () => {
      fetchData()
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/loyalty-target', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Data-Volume_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const submitData = async () => {
      Modal.confirm({
        title:
          modalRef.value.edit === true
            ? `Konfirmasi Mengubah Target Volume`
            : 'Konfirmasi Ajukan Target Volume',
        content:
          modalRef.value.edit === true
            ? 'Apakah anda ingin melanjutkan ubah data ?'
            : 'Apakah anda ingin melanjutkan pengajuan ?',
        onOk() {
          const form_data = new FormData()

          form_data.append('package_id', modalRef.value.formData.package_id)
          form_data.append('program_id', modalRef.value.formData.program_id)
          form_data.append('vendor_code', modalRef.value.formData.vendor_code.toString())
          form_data.append('target_name', modalRef.value.formData.target_name)
          form_data.append('section_name', modalRef.value.formData.section_name)
          form_data.append('section_description', modalRef.value.formData.section_description)
          modalRef.value.formData.dataBrand.forEach((element, index) => {
            form_data.append(`brand[${index}]`, element.brand)
            form_data.append(`target_q1[${index}]`, element.target_q1)
            form_data.append(`target_q2[${index}]`, element.target_q2)
            form_data.append(`target_q3[${index}]`, element.target_q3)
            form_data.append(`target_q4[${index}]`, element.target_q4)
          })

          let formDataEdit = {
            package_id: modalRef.value.formData.package_id,
            target_name: modalRef.value.formData.target_name,
            section_name: modalRef.value.formData.section_name,
            section_description: modalRef.value.formData.section_description,
            target_q1: modalRef.value.formData.target_q1,
            target_q2: modalRef.value.formData.target_q2,
            target_q3: modalRef.value.formData.target_q3,
            target_q4: modalRef.value.formData.target_q4,
          }

          if (modalRef.value.formData.target_id) {
            apiClient
              .put(`/api/loyalty-target/${modalRef.value.formData.target_id}`, formDataEdit)
              .then(({ data }) => {
                modal3Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil diubah')
              })
              .catch(({ response: { data, status } }) => {
                if (status === 422) {
                  data[0].message
                } else data.message = 'Gagal mengubah data! cek data kembali'
                Modal.error({
                  title: 'Gagal Simpan',
                  content: data[0].message,
                  onOk() {
                    console.log('ok')
                  },
                })
              })
              .finally(() => (isPosting.value = false))
          } else {
            apiClient
              .post(`/api/loyalty-target`, form_data)
              .then(({ data }) => {
                modal3Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil ditambah')
              })
              .catch(({ response: { data, status } }) => {
                if (status === 422) {
                  data.message
                } else data.message = 'Gagal menyimpan data! cek data kembali'
                Modal.error({
                  title: 'Gagal Simpan',
                  content: data.message,
                  onOk() {
                    console.log('ok')
                  },
                })
              })
              .finally(() => (isPosting.value = false))
          }
        },
        onCancel() {},
      })
    }

    const submitEditVolume = () => {
      Modal.confirm({
        title: `Konfirmasi Mengubah Volume`,
        content: 'Apakah anda ingin melanjutkan mengubah data ?',
        onOk() {
          let fromDataVolume = {
            volume: modalRefHistoryVolume.value.formData.volume,
          }
          apiClient.put(
            `/api/loyalty-target-histories/${modalRefHistoryVolume.value.formData.volume_id}`,
            fromDataVolume,
          )
          .then(({ data }) => {
            modal4Visible.value = false
            // modalRefHistoryVolume.value.formData.volume_id = null
            // formState.value.isEdit = false
            isPosting.value = false
            fetchData()
            message.success('Berhasil diubah')
          })
          .catch(({ response: { data, status } }) => {
            if (status === 422) {
              data[0].message
            } else data.message = 'Gagal mengubah data! cek data kembali'
            Modal.error({
              title: 'Gagal Simpan',
              content: data[0].message,
              onOk() {
                console.log('ok')
              },
            })
          })
        },
      })
    }

    const submitVolume = () => {
      Modal.confirm({
        title: `Konfirmasi Mengubah Volume`,
        content: 'Apakah anda ingin melanjutkan mengubah data ?',
        onOk() {
          let fromDataVolume = {
            target_id: modalRefVolume.value.formData.target_id,
            volume: modalRefVolume.value.formData.volume,
            date: modalRefVolume.value.formData.date,
          }

          if (modalRefVolume.value.editData === false) {
            apiClient
              .post(`/api/loyalty-target-histories`, fromDataVolume)
              .then(({ data }) => {
                modal5Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil ditambahkan')
              })
              .catch(({ response: { data, status } }) => {
                if (status === 422) {
                  data[0].message
                } else data.message = 'Gagal menambahkan data! cek data kembali'
                Modal.error({
                  title: 'Gagal Simpan',
                  content: data[0].message,
                  onOk() {
                    console.log('ok')
                  },
                })
              })
          } else {
            apiClient
              .put(
                `/api/loyalty-target-histories/${modalRefVolume.value.formData.target_id}`,
                fromDataVolume,
              )
              .then(({ data }) => {
                modal5Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil diubah')
              })
              .catch(({ response: { data, status } }) => {
                if (status === 422) {
                  data[0].message
                } else data.message = 'Gagal mengubah data! cek data kembali'
                Modal.error({
                  title: 'Gagal Simpan',
                  content: data[0].message,
                  onOk() {
                    console.log('ok')
                  },
                })
              })
          }
        },
      })
    }

    const onSelectChange = (selectedRowKeys, selectedRows, dataIndex) => {
      state.selectedRowKeys = selectedRowKeys
      state.selectedRows = selectedRows
    }

    const visible = ref(false)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    provide('formState', formState)

    onMounted(() => {
      fetchData()
      fetchDataBrand()
    })

    return {
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      rowSelection,
      columns,
      data,
      q,
      searchInput,
      startRow,
      region,
      area,
      brands,
      program,
      toko,
      state,
      ...toRefs(state),
      fetchData,
      search,
      postDelete,
      modal2Visible,
      modal3Visible,
      modal4Visible,
      modal5Visible,
      setModal1Visible,
      fetchXlsx,
      isPosting,
      formState,
      onSelectChange,
      visible,
      lihatHistory,
      handleTableChange,
      dataRegional,
      dataArea,
      loading,
      confirm,
      modalRef,
      modalRefVolume,
      modalRefHistoryVolume,
      errorMessage,
      realisasiData,
      submitData,
      submitVolume,
      submitEditVolume,
      dataBrandList,
    }
  },
  data() {
    return {
      formData: {
        target_id: '',
        package_id: '',
        program_id: '',
        vendor_code: '',
        vendor_name: '',
        brand: '',
        target_name: '',
        section_name: '',
        section_description: '',
        target_q1: '',
        target_q2: '',
        target_q3: '',
        target_q4: '',
      },
      dataBrand: [],
      dataVolume: {},
      editDataVolume: false,
    }
  },

  mounted() {
    this.getDataBrand()
  },

  methods: {
    editTarget(items) {
      this.editData = true
      this.modal3Visible = true
      this.formState.title_state = 'Edit Target Volume'
      this.formState.state = 'edit'
      this.formState.readOnly = false
      this.formData.target_id = items.target_id
      this.formData.program_id = items.program_id
      this.formData.package_id = items.package_id
      this.formData.vendor_code = items.vendor_code
      this.formData.vendor_name = items.vendor_name
      this.formData.brand = items.brand
      this.formData.target_name = items.target_name
      this.formData.section_name = items.section_name
      this.formData.section_description = items.section_description
      this.formData.target_q1 = items.target_q1
      this.formData.target_q2 = items.target_q2
      this.formData.target_q3 = items.target_q3
      this.formData.target_q4 = items.target_q4
    },

    getDataBrand() {
      apiClient.get('/api/filter/brand?active=1').then(response => {
        this.dataBrand = response.data
      })
    },

    openModalVolume() {
      this.formState = {}
      this.modal3Visible = true
      this.formState.title_state = 'Tambah Target Volume'
      this.formState.state = 'new'
      this.formState.readOnly = false
      this.refreshDataVolume()
    },

    refreshDataVolume() {
      this.formData.target_id = ``
      this.formData.program_id = ``
      this.formData.package_id = ``
      this.formData.vendor_code = ``
      this.formData.vendor_name = ``
      this.formData.brand = ``
      this.formData.target_name = ``
      this.formData.section_name = ``
      this.formData.section_description = ``
      this.formData.target_q1 = ``
      this.formData.target_q2 = ``
      this.formData.target_q3 = ``
      this.formData.target_q4 = ``
    },

    volumeData(items) {
      this.formData.target_id = items.target_id
      this.formData.section_name = items.section_name
      this.formData.program_id = items.program_id
      this.formData.vendor_name = items.vendor_name
      this.modal5Visible = true
      this.formState.title_state = 'Tambah Volume'
      this.dataVolume = ''
      this.editDataVolume = false
    },

    editVolume(items) {
      this.formData.target_id = items.target_id
      this.modal5Visible = true
      this.formState.title_state = 'Edit Volume'
      this.dataVolume = items
      this.editDataVolume = true
    },
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
</style>
