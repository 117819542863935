<template>
  <a-form
    ref="formRef"
    class="myform"
    :model="formState"
    :rules="rules"
    :wrapper-col="{ span: 14 }"
    :scroll-to-first-error="true"
  >
    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Judul"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
        >
          <a-input v-model:value="formData.section_name" placeholder="Judul" maxlength="50" @change="showAlertInput('section_name')"/>
          <a-label class="text-danger" v-show="alertSectionName">Judul harus diisi.</a-label>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Nama Target"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
        >
          <a-input placeholder="Nama Target" v-model:value="formData.target_name" maxlength="50" @change="showAlertInput('target_name')"/>
          <a-label class="text-danger" v-show="alertTargetName">Nama target harus diisi.</a-label>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Deskripsi"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
        >
          <a-textarea
            placeholder="Deskripsi"
            :auto-size="{ minRows: 2, maxRows: 5 }"
            v-model:value="formData.section_description"
            maxlength="100"
            @change="showAlertInput('section_description')"
          />
          <a-label class="text-danger" v-show="alertSectionDescription">Deskripsi harus diisi.</a-label>
        </a-form-item>
      </a-col>
    </a-row>

    <hr />
    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Nama Toko"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          has-feedback
          required
        >
          <filter-customer
            v-model:value="formData.vendor_code"
            placeholder="Nama Toko"
            :disabled="edit"
            style="width:100%"
            required
            :mode="null"
            @change="changeCustomer"
          ></filter-customer>
          <a-label class="text-danger" v-show="alertToko">Toko harus diisi.</a-label>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Nama Program"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          has-feedback
          required
        >
          <filter-program
            v-model:value="formData.program_id"
            v-model:toko="formData.vendor_code"
            :disabled="edit"
            placeholder="Nama Program"
            style="width:100%"
            required
            :mode="null"
            @change="changeProgramByStore(formData.vendor_code, formData.program_id)"
          ></filter-program>
          <a-label class="text-danger" v-show="alertProgram">Program harus diisi.</a-label>
          <a-label class="text-danger" v-show="showAlert">Toko tidak terdaftar program tipe UpFront / Uom tidak tersedia.</a-label>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="form-row" v-if="formData.target_id">
      <a-col :sm="24">
        <a-form-item
          label="Volume"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
        >
          <a-form-item :label-col="{ sm: { span: 4 } }" :wrapper-col="{ sm: { span: 24 - 4 } }">
            <a-col :sm="{ span: 24, offset: 0 }" :md="{ span: 24, offset: 0 }">
              <a-row class="myform-vertical" layout="vertical">
                <a-row class="form-row">
                  <a-col :md="6">
                    <a-form-item
                      :label="`Brand`"
                      label-align="left"
                      :name="['brands', index, 'brand_id']"
                      :wrapper-col="{ span: 24 }"
                    >
                    <a-input disabled v-model:value="formData.brand"></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :md="4">
                    <a-form-item label="Target Q1" label-align="left" required>
                      <a-input v-model:value="formData.target_q1" style="width: 100%" @keypress="checkInput($event)" type="number" required></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :md="4">
                    <a-form-item label="Target Q2" label-align="left" required>
                      <a-input v-model:value="formData.target_q2" style="width: 100%" @keypress="checkInput($event)" type="number" required></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :md="4">
                    <a-form-item label="Target Q3" label-align="left" required>
                      <a-input v-model:value="formData.target_q3" style="width: 100%" @keypress="checkInput($event)" type="number" required></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :md="4">
                    <a-form-item label="Target Q4" label-align="left" required>
                      <a-input v-model:value="formData.target_q4" style="width: 100%" @keypress="checkInput($event)" type="number" required></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-row>
            </a-col>
          </a-form-item>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row v-else class="form-row" v-show="showVolume">
      <a-col :sm="24">
        <a-form-item
          label="Volume"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
        >
          <a-form-item :label-col="{ sm: { span: 4 } }" :wrapper-col="{ sm: { span: 24 - 4 } }">
            <a-col :sm="{ span: 24, offset: 0 }" :md="{ span: 24, offset: 0 }">
              <a-row class="myform-vertical" layout="vertical">
                <template v-for="(item, index) in formData.dataBrand" :key="index">
                  <a-row class="form-row">
                    <a-col :md="6">
                      <a-form-item
                        :label="item.name"
                        label-align="left"
                        :name="['brands', index, 'brand_id']"
                        :wrapper-col="{ span: 24 }"
                      >
                      <a-input disabled v-model:value="item.brand"></a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :md="4">
                      <a-form-item label="Target Q1" label-align="left" required>
                        <a-input v-model:value="item.target_q1" style="width: 100%" @keypress="checkInput($event)" type="number" required></a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :md="4">
                      <a-form-item label="Target Q2" label-align="left" required>
                        <a-input v-model:value="item.target_q2" style="width: 100%" @keypress="checkInput($event)" type="number" required></a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :md="4">
                      <a-form-item label="Target Q3" label-align="left" required>
                        <a-input v-model:value="item.target_q3" style="width: 100%" @keypress="checkInput($event)" type="number" required></a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :md="4">
                      <a-form-item label="Target Q4" label-align="left" required>
                        <a-input v-model:value="item.target_q4" style="width: 100%" @keypress="checkInput($event)" type="number" required></a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </template>
              </a-row>
            </a-col>
          </a-form-item>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>
<script>
import { defineComponent } from 'vue'
import apiClient from '@/services/axios'
import FilterCustomer from '@/components/filter/FilterTokoByCode'
import moment from 'moment'
import FilterProgram from '@/components/filter/FilterProgramByStore'
// import FilterPackage from '@/components/filter/FilterLoyaltyPackage'
import rules from './rules'

export default {
  components: {
    FilterCustomer,
    FilterProgram,
    // FilterPackage,
  },
  props: {
    dataTarget: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      formData: {
        target_id: this.dataTarget.target_id,
        package_id: this.dataTarget.package_id,
        program_id: this.dataTarget.program_id,
        vendor_code: this.dataTarget.vendor_code,
        target_name: this.dataTarget.target_name,
        section_name: this.dataTarget.section_name,
        section_description: this.dataTarget.section_description,
        // edit data
        brand: this.dataTarget.brand,
        target_q1: this.dataTarget.target_q1,
        target_q2: this.dataTarget.target_q2,
        target_q3: this.dataTarget.target_q3,
        target_q4: this.dataTarget.target_q4,

        // add data
        dataBrand: [],
      },
      edit: false,
      dataBrand: [],
      // showBrand: false,
      showVolume: false,
      showAlert: false,

      alertSectionName: false,
      alertTargetName: false,
      alertSectionDescription: false,
      alertToko: false,
      alertProgram: false,

      volume1: {
        name: `Volume 1`,
        brand: this.dataTarget.brand,
        target_q1: this.dataTarget.target_q1,
        target_q2: this.dataTarget.target_q2,
        target_q3: this.dataTarget.target_q3,
        target_q4: this.dataTarget.target_q4,
      },
      volume2: {
        name: `Volume 2`,
        brand: this.dataTarget.brand,
        target_q1: this.dataTarget.target_q1,
        target_q2: this.dataTarget.target_q2,
        target_q3: this.dataTarget.target_q3,
        target_q4: this.dataTarget.target_q4,
      },
      volume3: {
        name: `Volume 3`,
        brand: this.dataTarget.brand,
        target_q1: this.dataTarget.target_q1,
        target_q2: this.dataTarget.target_q2,
        target_q3: this.dataTarget.target_q3,
        target_q4: this.dataTarget.target_q4,
      },
      volume4: {
        name: `Volume 4`,
        brand: this.dataTarget.brand,
        target_q1: this.dataTarget.target_q1,
        target_q2: this.dataTarget.target_q2,
        target_q3: this.dataTarget.target_q3,
        target_q4: this.dataTarget.target_q4,
      },
      rules,
    }
  },
  computed: {
    checkValidation() {
      return (
        this.formData.package_id === '' ||
        this.formData.program_id === '' ||
        this.formData.vendor_code === '' ||
        this.formData.brand === '' ||
        this.formData.target_name === '' ||
        this.formData.section_name === '' ||
        this.formData.section_description === '' ||
        this.formData.target_q1 === '' ||
        this.formData.target_q2 === '' ||
        this.formData.target_q3 === '' ||
        this.formData.target_q4 === ''
      )
    },
  },
  async mounted() {
    await this.getDataBrand()
    if (this.formData.target_id) {
      this.edit = true
    } else {
      this.edit = false
    }
  },
  methods: {
    pushVolume() {
      this.formData.dataBrand.push(this.volume1);
      this.formData.dataBrand.push(this.volume2);
      this.formData.dataBrand.push(this.volume3);
      this.formData.dataBrand.push(this.volume4);
    },
    changeCustomer() {
      this.showVolume = false;
      this.showAlert = false;

      if (typeof(this.formData.vendor_code) == 'undefined' || this.formData.vendor_code == null || !this.formData.vendor_code) { this.alertToko = true } else { this.alertToko = false }
    },
    changeProgramByStore(code, id) {
      if (typeof(this.formData.program_id) == 'undefined' || this.formData.program_id == null || !this.formData.program_id) { this.alertProgram = true; this.showAlert = false; } else { this.alertProgram = false; this.showAlert = false; }
      const _params = {
        program_id: id,
        vendor_code: code,
      }
      apiClient
      .get('/api/loyalty-target-volume/get-brand', { params: _params })
      .then(response => {
        let volumeCount = response.data.count
        let index = 0;
        if (response.data.count > 0 && response.data.tipe == "upfront") {
          this.formData.dataBrand.splice(0);
          this.pushVolume();

          this.showVolume = true;
          this.showAlert = false;

          this.formData.dataBrand[index++].brand = response.data.volume1;
          this.formData.dataBrand[index++].brand = response.data.volume2;
          this.formData.dataBrand[index++].brand = response.data.volume3;
          this.formData.dataBrand[index++].brand = response.data.volume4;

          for (let i = 4; i >= volumeCount; i--) {
            this.formData.dataBrand.splice(i, 1);
          }
        } else {
          this.showVolume = false;
          this.showAlert = true;
          if (typeof(this.formData.program_id) == 'undefined' || this.formData.program_id == null || !this.formData.program_id) { this.alertProgram = true; this.showAlert = false; } else { this.alertProgram = false; }
          this.formData.dataBrand.splice(0);
        }
      })
    },
    changeMerchant(item) {
      this.formData.vendor_code = item
    },

    changeProgram(item) {
      this.formData.program_id = item
    },

    changeKemasan(item) {
      this.formData.package_id = item
    },
    changeBrand(item) {
      let SearchData = this.dataBrand.find(x => x.id === item)
      this.formData.brand = SearchData.name

      console.log(`this.formData.brand`, this.formData.brand)
    },
    getDataBrand() {
      apiClient.get('/api/filter/brand?active=1').then(response => {
        this.dataBrand = response.data
      })
    },
    showAlertInput(item){
      if (item === 'section_name' && this.formData.section_name.length <= 0) { this.alertSectionName = true } else { this.alertSectionName = false }
      if (item === 'target_name' && this.formData.target_name.length <= 0) { this.alertTargetName = true } else { this.alertTargetName = false }
      if (item === 'section_description' && this.formData.section_description.length <= 0) { this.alertSectionDescription = true } else { this.alertSectionDescription = false }
    },
    checkInput(e) {
      // if (this.formData.volume.length >= 5) {
      //   e.preventDefault()
      // }
      if (e.key == '-') {
        e.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
