<template>
  <a-form class="myform" :wrapper-col="{ span: 14 }" :scroll-to-first-error="true" ref="formRef">
    <a-row class="form-row">
      <a-col :sm="24" class="mb-n4">
        <a-form-item
          label="Judul"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          has-feedback>
          <span class="ant-form-text">{{formData.section_name}}</span>
        </a-form-item>
      </a-col>
      <a-col :sm="24" class="mb-n4">
        <a-form-item
          label="Program"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          has-feedback
        >
        <span class="ant-form-text">{{program_name}}</span>
        </a-form-item>
      </a-col>
      <a-col :sm="24" class="mb-n4">
        <a-form-item
          label="Nama Toko"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          has-feedback
        >
        <span class="ant-form-text">{{formData.vendor_name}}</span>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="form-row">
      <a-col :sm="24" class="mb-n4">
        <a-form-item
          label="Volume"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          has-feedback
        >
          <a-input
            type="number"
            v-model:value="formData.volume"
            placeholder="Volume"
            class="w-100 mb-n2"
            maxlength="30"
            @keypress="checkInput($event)"
          ></a-input>
          <span class="text-danger" v-if="String(formData.volume).length > 8 && formData.volume != null">Maksimal 8 Karakter</span>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="form-row">
      <a-col :sm="24" class="mb-n4">
        <a-form-item
          label="Tanggal"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          has-feedback
        >
          <a-date-picker class="w-100" v-model:value="formData.date" placeholder="Tanggal" />
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>
<script>
import apiClient from '@/services/axios'
export default {
  props: {
    targetId: {
      type: Object,
      default: null,
    },
    dataVolume: {
      type: Object,
      default: null,
    },
    editDataVolume: {
      type: Boolean,
    },
  },
  data() {
    return {
      program_name: '',
      formData: {
        target_id: this.targetId.target_id,
        section_name: this.targetId.section_name,
        program_id: this.targetId.program_id,
        vendor_name: this.targetId.vendor_name,
        volume: this.dataVolume.volume,
        date: '',
      },
      editData: this.editDataVolume,
    }
  },
  mounted() {
    apiClient
      .get('/api/filter/loyalty-program', { params: { id: this.targetId.program_id } })
      .then(response => {
        const sorted = response.data
        this.program_name = sorted[0].name
      })
  },

  methods: {
    checkInput(e) {
      if (this.formData.volume) {
        if (this.formData.volume.length >= 5) {
          e.preventDefault()
        }
      }
      if (e.key == '-') {
        e.preventDefault()
      }
    },
  },
}
</script>
