const columnsHistory = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    slots: { customRender: 'volume' },
  },
  {
    title: 'Kuartal',
    dataIndex: 'quartal',
  },
  {
    title: 'Tanggal',
    dataIndex: 'date',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
export default columnsHistory
